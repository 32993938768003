@import '@core/styles/_colors.scss';

.card-container {
	display: block;
	max-width: 260px;
	height: 312px;
	border-radius: 12px;
	position: relative;
	overflow: hidden;

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	& > .card-info {
		width: 95%;
		position: absolute;
		display: flex;
		justify-content: space-between;
		align-items: center;
		bottom: 8px;
		left: 2.5%;
		background: rgba(255, 255, 255, 0.7);
		-webkit-backdrop-filter: blur(30px);
		backdrop-filter: blur(30px);
		border-radius: 4px;
		padding: 8px;

		& > p {
			width: 100%;
			height: 100%;
			font-weight: 400;
			font-size: 20px;
			color: $colorGray70;
			display: -webkit-box;
			display: -moz-box;
			-moz-box-orient: vertical;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			margin: 0;
		}

		& > .play-button {
      text-decoration: none;
			background: transparent;
			border: none;
			outline: none;
		}
	}
}
