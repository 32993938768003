@import '@core/styles/_colors.scss';

.lecture-container {
	width: 220px;
	max-width: 300px;
  // height: 279px;
  height: 100%;
	display: flex;
	flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  text-decoration: none;
  color: initial;

	& > img {
		flex: .5 0 100px;
		width: 95%;
		height: 50%;
    object-fit: cover;
		border-radius: 12px;
	}

	& > .lecture-bottom {
		flex: 0.5;
    flex-basis: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > .lecture-status {
      margin: 8px 0;
    }

		& > p {
      width: 100%;
			font-weight: 400;
			font-size: 20px;
			color: $colorGray70;
      display: -webkit-box;
			display: -moz-box;
			-moz-box-orient: vertical;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			line-clamp: 2;
      margin: 0;
		}

		& > .lecture-info {
			display: flex;
			align-items: center;
			gap: 8px;

			& > .dot {
				width: 4px;
				height: 4px;
				background: $colorGray30;
				border-radius: 50%;
			}

			& > .rating {
				display: flex;
				align-items: center;
				gap: 4px;
				font-weight: 700;
				font-size: 14px;
				color: $colorGray70;
			}

			& > .stuff {
				font-weight: 400;
				font-size: 12px;
				color: $colorGray40;
			}
		}
	}
}
